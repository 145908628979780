import { useEffect, useState } from 'react';
import { useNavigate, Link, Outlet, useLocation } from 'react-router-dom';
import { fetchBathroomPage, RoomData } from '../Data/kitchenBathroomData';

export default function Bathroom() {
  const [bathroomData, setBathroomData] = useState<RoomData | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const loadBathroomData = async () => {
      try {
        const data = await fetchBathroomPage();
        setBathroomData(data);
      } catch (error) {
        console.error('Error loading bathroom data:', error);
      }
    };

    loadBathroomData();

    if (location.pathname === '/bathroom') {
      navigate('modern');
    }
  }, [navigate, location.pathname]);

  if (!bathroomData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {/* Breadcrumb style links for Modern and Traditional Bathrooms */}
      <div className="mt-8 max-w-screen-xl mx-auto flex items-center space-x-4 text-sm tracking-wider font-light text-left">
        {/* Modern Bathroom Link */}
        <Link
          to="modern"
          className={`relative hover:text-black ${
            location.pathname.includes('modern') ? 'text-black font-semibold' : 'text-gray-600'
          }`}
        >
          Modern Bathrooms
          {location.pathname.includes('modern') && (
            <span className="absolute left-0 right-0 -bottom-1 mx-auto w-full border-b-2 border-black"></span>
          )}
        </Link>

        <span className="text-gray-400">{'>'}</span>

        {/* Traditional Bathroom Link */}
        <Link
          to="traditional"
          className={`relative hover:text-black ${
            location.pathname.includes('traditional') ? 'text-black font-semibold' : 'text-gray-600'
          }`}
        >
          Traditional Bathrooms
          {location.pathname.includes('traditional') && (
            <span className="absolute left-0 right-0 -bottom-1 mx-auto w-full border-b-2 border-black"></span>
          )}
        </Link>
      </div>

      {/* Outlet to render the selected (Modern/Traditional) component */}
      <div className="mt-10">
        <Outlet /> {/* This is where Modern/Traditional component will render without reloading the whole page */}
      </div>
    </div>
  );
}
