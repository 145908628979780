export interface ImageItem {
  type: 'image';
  imageUrl: string;
  category: string;
}

// Updated images for the Kitchen, Modern Bathroom, and Traditional Bathroom galleries
const placeholderImages: ImageItem[] = [
  // Kitchen Images
  {
    type: 'image',
    imageUrl: '/images/Kitchen/-IhIoytA.jpeg',
    category: 'kitchen',
  },
  // {
  //   type: 'image',
  //   imageUrl: '/images/Kitchen/-slirgvA.jpeg',
  //   category: 'kitchen',
  // },
  {
    type: 'image',
    imageUrl: '/images/Kitchen/2B_MeqLQ.jpeg',
    category: 'kitchen',
  },
  {
    type: 'image',
    imageUrl: '/images/Kitchen/2mWOU1zw.jpeg',
    category: 'kitchen',
  },
  {
    type: 'image',
    imageUrl: '/images/Kitchen/4LDwtCyA.jpeg',
    category: 'kitchen',
  },
  {
    type: 'image',
    imageUrl: '/images/Kitchen/4wt1U8Kw.jpeg',
    category: 'kitchen',
  },
  {
    type: 'image',
    imageUrl: '/images/Kitchen/9A1d_2wg.jpeg',
    category: 'kitchen',
  },
  {
    type: 'image',
    imageUrl: '/images/Kitchen/BdRJMMxQ.jpeg',
    category: 'kitchen',
  },
  {
    type: 'image',
    imageUrl: '/images/Kitchen/BNQYYTmA.jpeg',
    category: 'kitchen',
  },
  {
    type: 'image',
    imageUrl: '/images/Kitchen/BnwvOv2g.jpeg',
    category: 'kitchen',
  },
  {
    type: 'image',
    imageUrl: '/images/Kitchen/dHGDEznA.jpeg',
    category: 'kitchen',
  },
  {
    type: 'image',
    imageUrl: '/images/Kitchen/GV4nDF7Q.jpeg',
    category: 'kitchen',
  },
  {
    type: 'image',
    imageUrl: '/images/Kitchen/Hlb109Jg.jpeg',
    category: 'kitchen',
  },
  {
    type: 'image',
    imageUrl: '/images/Kitchen/i6CXYwWA.jpeg',
    category: 'kitchen',
  },
  {
    type: 'image',
    imageUrl: '/images/Kitchen/jAZgENHA.jpeg',
    category: 'kitchen',
  },
  {
    type: 'image',
    imageUrl: '/images/Kitchen/JQELB7tw.jpeg',
    category: 'kitchen',
  },
  {
    type: 'image',
    imageUrl: '/images/Kitchen/jVeT7Fuw.jpeg',
    category: 'kitchen',
  },
  // {
  //   type: 'image',
  //   imageUrl: '/images/Kitchen/I7DHzaiA.jpeg',
  //   category: 'kitchen',
  // },
  {
    type: 'image',
    imageUrl: '/images/Kitchen/n9FTTB4w.jpeg',
    category: 'kitchen',
  },
  {
    type: 'image',
    imageUrl: '/images/Kitchen/QXIxi3qw.jpeg',
    category: 'kitchen',
  },
  {
    type: 'image',
    imageUrl: '/images/Kitchen/TVYR5PDQ.jpeg',
    category: 'kitchen',
  },
  // {
  //   type: 'image',
  //   imageUrl: '/images/Kitchen/Wm2AAlgw.jpeg',
  //   category: 'kitchen',
  // },

  // Modern Bathroom Images
  {
    type: 'image',
    imageUrl: '/images/ModernBathroom/1.2mayford_furniture_v1_ls.jpg',
    category: 'modern-bathroom',
  },
  // {
  //   type: 'image',
  //   imageUrl: '/images/ModernBathroom/2.sarenna_v1_ls.jpg',
  //   category: 'modern-bathroom',
  // },
  // {
  //   type: 'image',
  //   imageUrl: '/images/ModernBathroom/apex_hinged_enclosures_v1_ls.jpg',
  //   category: 'modern-bathroom',
  // },
  // {
  //   type: 'image',
  //   imageUrl: '/images/ModernBathroom/apex_offset_quad_enclosures_v1_ls.jpg',
  //   category: 'modern-bathroom',
  // },
  // {
  //   type: 'image',
  //   imageUrl: '/images/ModernBathroom/apex_sliding_enclosures_v1_ls.jpg',
  //   category: 'modern-bathroom',
  // },
  {
    type: 'image',
    imageUrl: '/images/ModernBathroom/arlo_ceramics_v2_ls1.jpg',
    category: 'modern-bathroom',
  },
  {
    type: 'image',
    imageUrl: '/images/ModernBathroom/ath077c_furniture_v1_ls1.jpg',
    category: 'modern-bathroom',
  },
  {
    type: 'image',
    imageUrl: '/images/ModernBathroom/lq043_mirrors_v1_ls.jpg',
    category: 'modern-bathroom',
  },
  {
    type: 'image',
    imageUrl: '/images/ModernBathroom/lq084_mirrors_v1_ls.jpg',
    category: 'modern-bathroom',
  },
  {
    type: 'image',
    imageUrl: '/images/ModernBathroom/maya_ceramics_v2_ls.jpg',
    category: 'modern-bathroom',
  },
  {
    type: 'image',
    imageUrl: '/images/ModernBathroom/nbb001_baths_v2_ls.jpg',
    category: 'modern-bathroom',
  },
  // {
  //   type: 'image',
  //   imageUrl: '/images/ModernBathroom/off973_baths_panels_v2_ls.jpg',
  //   category: 'modern-bathroom',
  // },
  {
    type: 'image',
    imageUrl: '/images/ModernBathroom/otley_baths_v2_ls.jpg',
    category: 'modern-bathroom',
  },
  {
    type: 'image',
    imageUrl: '/images/ModernBathroom/qua002_furniture_v1_ls2.jpg',
    category: 'modern-bathroom',
  },
  {
    type: 'image',
    imageUrl: '/images/ModernBathroom/roomset_athena_square_bath_v1_ls.jpg',
    category: 'modern-bathroom',
  },
  {
    type: 'image',
    imageUrl: '/images/ModernBathroom/roomset_athena_wetroom_v1_ls.jpg',
    category: 'modern-bathroom',
  },
  {
    type: 'image',
    imageUrl: '/images/ModernBathroom/wrsbp12_screens_v1_ls.jpg',
    category: 'modern-bathroom',
  },

  // Traditional Bathroom Images
  {
    type: 'image',
    imageUrl: '/images/TraditionalBathroom/back_to_wall_baths_v1_ls.jpg',
    category: 'traditional-bathroom',
  },
  {
    type: 'image',
    imageUrl: '/images/TraditionalBathroom/kensington_baths_v1_ls.jpg',
    category: 'traditional-bathroom',
  },
  // {
  //   type: 'image',
  //   imageUrl: '/images/TraditionalBathroom/lof233_furniture_v1_ls.jpg',
  //   category: 'traditional-bathroom',
  // },
  {
    type: 'image',
    imageUrl: '/images/TraditionalBathroom/lof268_furniture_v2_ls1.jpg',
    category: 'traditional-bathroom',
  },
  {
    type: 'image',
    imageUrl: '/images/TraditionalBathroom/Belgravia.jpg',
    category: 'traditional-bathroom',
  },
  {
    type: 'image',
    imageUrl: '/images/TraditionalBathroom/Empire.jpg',
    category: 'traditional-bathroom',
  },
  {
    type: 'image',
    imageUrl: '/images/TraditionalBathroom/Hillingdon.jpg',
    category: 'traditional-bathroom',
  },
  {
    type: 'image',
    imageUrl: '/images/TraditionalBathroom/Victorian.jpg',
    category: 'traditional-bathroom',
  },
];

export const fetchGalleryImages = async (category: string): Promise<ImageItem[]> => {
  return placeholderImages.filter((item) => item.category === category);
};
